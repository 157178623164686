import type {
  AttachBuyerToPaymentTunnelResult,
  BeneficiaryInfos,
  BuyerInfos,
  CreatePaymentTransactionResult,
  ShippingInfos
} from '~/@types/graphql-police'
import { documents } from './documents'
import { requestPolice } from './helpers/requestPolice'

export function createGiftPaymentTransaction() {
  return requestPolice<CreatePaymentTransactionResult>(
    documents.mutations.createGiftPaymentTransactionDocument
  )
}

export function confirmStripeGiftPayment({
  clientSecret,
  buyerInfos,
  beneficiaryInfos,
  shippingInfos
}: {
  clientSecret: string
  buyerInfos: BuyerInfos
  beneficiaryInfos: BeneficiaryInfos
  shippingInfos: ShippingInfos | undefined
}) {
  return requestPolice<AttachBuyerToPaymentTunnelResult>(
    documents.mutations.attachInfosToGiftPaymentTunnelDocument,
    {
      clientSecret,
      buyerInfos,
      beneficiaryInfos,
      shippingInfos
    }
  )
}
